import { Injectable } from '@angular/core';
import { Selection } from '@services/core-services/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { Entry } from '@services/core-services/models/entry';

@Injectable({ providedIn: 'root' })
export class SelectionService {
  private selection = new BehaviorSubject<Selection<any>>({ list: [] });

  set<T>(selection: Selection<T>) {
    this.selection.next(selection);
  }

  get<T>(): Observable<Selection<T>> {
    return this.selection;
  }

  getSnapshot<T>(): Selection<T> {
    return this.selection.getValue();
  }

  public clearSelection() {
    this.selection.next({ list: [], selectionCount: 0, allSelected: false });
  }

  public updateSelection<T extends Entry>(entry: T | T[], selected: boolean = false) {
    const entries = Array.isArray(entry) ? entry : [entry];
    const selection = this.selection.getValue();
    const list = selected
      ? selection.list.concat(entries)
      : selection.list.filter(e => !entries.some(x => x.id === e.id));

    this.selection.next({ ...selection, list, selectionCount: list.length });
  }

  public selectAll() {
    const selection = this.selection.getValue();
    this.selection.next({ ...selection, allSelected: true });
  }

  public next<T>(list: T[], index: number) {
    const selection = this.selection.getValue();
    if (index > 0) {
      this.selection.next({ ...selection, list: [list[index - 1]] });
    }
  }

  public previous<T>(list: T[], index: number) {
    const selection = this.selection.getValue();
    if (index < list.length - 1) {
      this.selection.next({ ...selection, list: [list[index + 1]] });
    }
  }

  public add<T extends Entry>(entry: T) {
    const selection = this.selection.getValue();
    this.selection.next({ ...selection, list: [...selection.list, entry] });
  }

  public remove<T extends Entry>(entry: T) {
    const selection = this.selection.getValue();
    this.selection.next({ ...selection, list: selection.list.filter(e => e.id !== entry.id) });
  }
}
